import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="sub-text">Website in construction</div>
    </div>
  );
}

export default App;
